import React from "react"
import { StaticQuery, graphql } from "gatsby"
import BackgroundImage from "gatsby-background-image"

import SEO from "../components/seo"
import Layout from "../components/layout"
import Thumbnails from "../components/thumbnails"

class IndexPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = { index: "profile" }
    this.timeout = null
    this.slide = function (index) {
      if (this.timeout) {
        clearTimeout(this.timeout)
      }
      if (index !== undefined) {
        this.setState({ index: index })
      }
      this.timeout = setTimeout(
        function () {
          this.timeout = null
          let keys = Object.keys(this.props.images)
          let index = keys.findIndex(
            function (x) {
              return this.state.index === x
            }.bind(this)
          )
          this.setState({
            index: keys[(index + 1) % keys.length],
          })
        }.bind(this),
        7500
      )
    }
  }

  componentDidMount() {
    if (!this.timeout) {
      this.slide()
    }
  }

  componentWillUnmount() {
    if (this.timeout) {
      clearTimeout(this.timeout)
    }
  }

  componentDidUpdate() {
    if (!this.timeout) {
      this.slide()
    }
  }

  render() {
    return (
      <>
        <SEO title="Romain Cochet" />
        <Layout>
          <header>
            <h1>Hello,</h1>
            <main>
              <p>
                I am co-founder &amp; CTO at @smiirl. French, living in Lisboa.
                I love to help.
              </p>
              <p>
                Discover my friend's projects plato.paris, jitter.video, juch.fr,
                jaimelesstartups.fr, pinaffo-pluvinage.com, quatreepingles.fr,
                snackthis.co.
              </p>
              <p>
                Find me on{" "}
                <a href="https://www.instagram.com/romaincochet/">instagram</a>{" "}
                &amp; <a href="https://www.twitter.com/cochet/">twitter</a>.
              </p>
              <Thumbnails
                cb={this.slide}
                active={this.state.index}
                main={this}
              />
            </main>
          </header>
          <BackgroundImage
            Tag="section"
            className="slideshow"
            fluid={this.props.images[this.state.index].childImageSharp.fluid}
            backgroundColor={`#040e18`}
          ></BackgroundImage>
        </Layout>
      </>
    )
  }
}

export default () => (
  <StaticQuery
    query={graphql`
      query {
        profile: file(name: { eq: "flickr-profile" }) {
          childImageSharp {
            fluid(maxWidth: 1200) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        beach: file(name: { eq: "flickr-beach" }) {
          childImageSharp {
            fluid(maxWidth: 1200) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        road: file(name: { eq: "flickr-road" }) {
          childImageSharp {
            fluid(maxWidth: 1200) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        aviva: file(name: { eq: "flickr-aviva" }) {
          childImageSharp {
            fluid(maxWidth: 1200) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `}
    render={images => {
      return <IndexPage images={images} />
    }}
  />
)
