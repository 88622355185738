import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const Thumbnails = props => {
  const data = useStaticQuery(graphql`
    query {
      profile: file(name: { eq: "flickr-profile" }) {
        childImageSharp {
          fluid(maxWidth: 150) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      beach: file(name: { eq: "flickr-beach" }) {
        childImageSharp {
          fluid(maxWidth: 150) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      road: file(name: { eq: "flickr-road" }) {
        childImageSharp {
          fluid(maxWidth: 150) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      aviva: file(name: { eq: "flickr-aviva" }) {
        childImageSharp {
          fluid(maxWidth: 150) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)
  return (
    <div className="thumbnails">
      {Object.keys(data).map(
        function (props, index, i) {
          if(data[index] && data[index].childImageSharp)
          {
            return (
              <div className="around" key={i} onClick={props.cb.bind(props.main, index)} onKeyDown={props.cb.bind(props.main, index)} role="radio" tabIndex={i} aria-label="Thumbail" aria-checked={props.active === index}><Img
                fluid={{ ...data[index].childImageSharp.fluid, aspectRatio: 1 }}
                className={(props.active === index)?'selected':null}
              /></div>
            )
          }
          return (<></>);
        }.bind(null, props)
      )}
    </div>
  )
}

export default Thumbnails
