import React from "react"
import Div100vh from "react-div-100vh"

class Layout extends React.Component {
  constructor() {
    super()
    this.state = {
      isMobile: null,
    }
  }

  componentDidMount() {
    this.handleWindowSizeChange()
    window.addEventListener("resize", this.handleWindowSizeChange)
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleWindowSizeChange)
  }

  handleWindowSizeChange = () => {
    let isMobile = window.innerWidth <= 960
    if (isMobile !== this.state.isMobile) {
      this.setState({ isMobile: isMobile })
    }
  }

  render() {
    if (!this.state.isMobile) {
      return <Div100vh className="content">{this.props.children}</Div100vh>
    } else {
      return <div className="content">{this.props.children}</div>
    }
  }
}

export default Layout
